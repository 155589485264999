<template>
    <div class="home">
        <v-card class="mx-auto my-12" max-width="600" @contextmenu="fff">

            <video class="mx-auto col" controls controlslist="nodownload" @contextmenu="fff">
                <source :src="playing.linkVideo" type="video/mp4" />
            </video>


            <v-divider class="mx-4"></v-divider>

            <v-card-title>{{ playing.title }}</v-card-title>

            <v-card-text>
                {{ playing.description }}
            </v-card-text>


        </v-card>

    </div>
</template>

<script>
// @ is an alias to /src


export default {
    name: 'lesson',
    data: () => ({
        dt: [],
        ids: 0,

    }),
    mounted() {
        this.ids = this.$route.params.id;


    },
    watch: {
        $route(to, from) {
            this.ids = to.params.id;
            console.log(to);
            console.log(from);
            this.$router.go(0);
        }
    },
    computed: {
        desserts() {
            return this.$store.getters.getListLesson;
        },
        playing() {
            let t = [];
            for (let index = 0; index < this.desserts.length; index++) {
                if (this.desserts[index].id == this.ids) {

                    t = this.desserts[index];
                    break;
                }

            }
            return t;
        }
    },
    methods: {
        fff(e) {
            e.preventDefault();
        }
    }

}
</script>