<template>
    <v-row justify="center" style="margin-top: 10px;">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Video</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Название" v-model="dt.title" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Описание" v-model="dt.disc"
                                    hint="Краткое описание видео"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input accept="video/*" label="Загрузка видео" filled prepend-icon="mdi-camera"
                                    id="file" ref="file" v-on:change="handleFileUpload()" v-model="file"></v-file-input>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="red darken-1" v-if="dt.id!=0" text @click="delVideo(dt.id)">
                        Удалить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Закрыть
                    </v-btn>
                    <v-btn color="green darken-1" text @click="saveFirm">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="6" style="text-align: center;">Видео</v-col>
        <v-col cols="12" md="6">
            <v-btn @click="dialog = true">
                Добавить
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-data-table :headers="headers" :items="desserts" :items-per-page="20" class="elevation-1"
                @click:row="openDialog"></v-data-table>
        </v-col>
    </v-row>
</template>

<script>


export default {
    name: 'VideoAdd',
    data: () => ({
        dialog: false,
        headers: [
            {
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'Название', value: 'title' },
            { text: 'Описание', value: 'description' },
            { text: 'Ссылка', value: 'linkVideo' },
            { text: 'Дата содания', value: 'dateCreate' },
        ],

        dt: {
            id: 0,
            title: '',
            disc: '',
            link: '',
        },
        file: '',
    }),
    mounted() {
        this.$store.dispatch("getlistPage");
    },
    computed: {
        desserts() {
            return this.$store.getters.getListLesson;
        },
    },
    methods: {

        createdialog() {
            this.dt.id = 0;
            this.dt.title = '';
            this.dt.disc = '';
            this.dt.link = '';
            this.dialog = true;
        },

        saveFirm() {
            this.$store.dispatch("saveUserLesson", this.dt);
            this.dialog = false;
        },
        openDialog(e) {
            this.dt.id = e.id;
            this.dt.title = e.title;
            this.dt.disc = e.description;
            this.dt.link = e.linkVideo;
            this.dialog = true;
        },

        handleFileUpload() {
            console.log(this.file)
            this.$store.dispatch("saveUserVideo", this.file).then(res => {
                this.dt.link = 'https://nis.lang.su//public/video/' + res
            });
        },
        delVideo(t){
            let r = confirm('Вы уверены что хотите удалить?');
            if(r){
                this.$store.dispatch("delUserVideo", t);
                this.dialog = false;
            }
        }

    },
}
</script>