import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Admin from './module/admin.js'
import Meneger from './module/meneger.js'
import Users from './module/users.js'
import Main from './module/main.js'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    Admin,
    Meneger,
    Users,
    Main
  }
})

export default store
