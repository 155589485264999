<template>
    <v-row justify="center" style="margin-top: 10px;">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">statistic</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Название" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Описание" hint="Краткое описание видео"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input accept="video/*" label="Загрузка видео"></v-file-input>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="6" style="text-align: center;">Видео</v-col>
        <v-col cols="12" md="6">
            <v-btn @click="dialog = true">
                Добавить
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-data-table :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1"></v-data-table>
        </v-col>
    </v-row>
</template>

<script>


export default {
    name: 'Statistic',
    data: () => ({
        dialog: false,
        headers: [
            {
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'Пользователь', value: 'calories' },
            { text: 'Действие', value: 'fat' },
            { text: 'Время', value: 'carbs' },
            
        ],
        desserts: [
            
        ],
    }),
}
</script>