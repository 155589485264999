<template>
    <v-container>
      Cabinet
    </v-container>
  </template>
  
  <script>
  
    export default {
      name: 'Cabinet',
  
      components: {
        
      },
    }
  </script>
  