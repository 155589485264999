<template>
    <div class="home">
        <v-col cols="12">
            <v-data-table :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1"></v-data-table>
        </v-col>
      
    </div>
  </template>
  
  <script>
 
  
  export default {
    name: 'log',
    data: () => ({
        dialog: false,
        headers: [
            {
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            { text: 'textAction', value: 'textAction' },
            { text: 'date', value: 'dtEvent' },
            { text: 'User', value: 'id_person' },
        ],
    }),
    mounted() {
        this.$store.dispatch("getlistLog");
    },
    computed: {
        desserts() {
            return this.$store.getters.getListLog;
        },
    },
  }
  </script>
  