<template>
  <v-app>
    <v-app-bar app color="#ed1c24" dark>
      <v-app-bar-nav-icon v-if="isAuthUser" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div style="background-color: #fff;">
        <img alt="logo" style="height: 50px;  margin: 2px; filter: drop-shadow(1px 1px 1px  rgba(255,255,255,0.5));" src="@/assets/logoMaxi.png">
      
      </div>
      <v-spacer></v-spacer>
      Обучение персонала
      <v-spacer></v-spacer>
      <v-btn v-if="isAuthUser" @click="onExit" target="_blank" text>
        <span class="mr-2">Выход</span>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute bottom temporary>
      <v-list nav dense>
        {{ fio }}
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
         
          <v-list-item v-for="(n, i) in menuList" :key="i" link :to="n.link">
            <v-list-item-title >{{ n.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    onExit() {
      this.$store.dispatch("getExitClient");
      if (this.isAuthUser == false) {
        this.$router.push('/')
      }
    }
  },
  computed: {
    fio() {
      return this.$store.getters.getFio;
    },
    menuList() {
      let t = this.$store.getters.getMenuList;
      console.log(t);
      return t;
    },
    isAuthUser() {
      return this.$store.getters.getAuthClient;
    },
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
};
</script>
