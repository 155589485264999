import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Cabinet from '../views/Cabinet.vue'
import Person from '../views/auth/Person.vue'
import store from "../store/index.js"
import Video from "../views/Video.vue"
import Logger from "../views/admin/Log.vue"
import Statistic from "../views/meneger/Statistic.vue"
import Lesson from '../views/client/Lesson.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/cabinet',
    name: 'Cabinet',
    component: Cabinet,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/person',
    name: 'Person',
    component: Person,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/video/list',
    name: 'video',
    component: Video,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/log',
    name: 'log',
    component: Logger,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statistic',
    name: 'statistic',
    component: Statistic,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/les/:id',
    name: 'lesson',
    component: Lesson,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.getAuthClient) {
      localStorage.setItem("maxsi-user-link", to.path);
      console.log(to.path);
      console.log(store.getters.getAuthClient);
      next()
      return
    }
    next('/') 
  } else {
    next() 
  }
})


export default router;