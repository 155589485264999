<template>
    <v-container>
        <v-row>
            <v-dialog v-model="dialog" persistent max-width="600px">

                <v-card>
                    <v-card-title>
                        <span class="text-h5">Профиль</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field label="Фамилия" 
                                    v-model="pers.lnm" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field label="Имя"
                                    v-model="pers.fnm" ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field label="Отчество" v-model="pers.tnm" 
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Логин" v-model="pers.lg" 
                                    required></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Password*" type="password" 
                                    v-model="pers.ps" required></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select :items="['Администратор', 'Менеджер', 'Пользователь']" v-model="pers.st" label="Статус"
                                        required></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="blue darken-1" v-if="pers.id!=0" text @click="deleteDialog(pers.id)">
                            Удалить
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialog = false">
                            Отмена
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="saveDialog">
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-btn @click="openDialog">Добавить</v-btn>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="desserts" :search="search" @click:row="openRead"></v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
    name: 'Person',
    data: () => ({
        dialog: false,
        pers: {
            id: 0,
            fnm: '',
            lnm: '',
            tnm: '',
            lg: '',
            ps: '',
            st: 'Пользователь',
        },
        search: '',
        headers: [
            {
                text: 'Id',
                value: 'id',
            },
            { text: 'Логин', value: 'login' },
            { text: 'Фамилия', value: 'lname' },
            { text: 'Имя', value: 'fname' },
            { text: 'Отчество', value: 'tname' },
            { text: 'Статус', value: 'nameStatus' },
            { text: 'Действие', value: 'act' },
        ],


    }),
    mounted() {
        this.$store.dispatch("getlistPerson");
    },
    computed: {
        desserts() {
            return this.$store.getters.getPersonList;
        },
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        deleteDialog(t) {
            let r = confirm('Вы уверены?')
            if (r) {
                this.$store.dispatch("dellistPerson", t);
                this.dialog = false;
            }
        },
        saveDialog(){
            this.$store.dispatch("SetlistPerson", this.pers);
            this.pers = {
            id: 0,
            fnm: '',
            lnm: '',
            tnm: '',
            lg: '',
            ps: '',
            st: 'Пользователь',
            };
            this.dialog = false;
        },
        openRead(e){
            this.pers = {
            id: e.id,
            fnm: e.fname,
            lnm: e.lname,
            tnm: e.tname,
            lg: e.login,
            ps: '',
            st: e.nameStatus,
            };
            console.log(this.pers);
            this.dialog = true;
        },
    },
    components: {

    },
}
</script>