import Vue from 'vue'
import Vuex from 'vuex'
//import Axios from '../../plugins/axios'
import Axios from 'axios';

Vue.use(Vuex)

class User {
    constructor(id) {
        this.id = id;
    }
}

export default ({
    state: {
        user: null,
        avt: false,
        ListMainSpec: [],
        load: false,
        listUsers: [],
        listUsersSelect: [],
        menuList: [],
        ListPerson: [],
        listLesson: [],
        listLog: [],
        fio: '',
    },
    getters: {
        getLoad(state) {
            return state.load;
        },
        listUsers(state) {
            return state.listUsers;
        },
        listUsersSelect(state) {
            return state.listUsersSelect;
        },
        getAuthClient(state) {
            return state.avt;
        },
        getMenuList(state) {
            return state.menuList;
        },
        getPersonList(state) {
            return state.ListPerson;
        },
        getListLesson(state) {
            return state.listLesson
        },
        getListLog(state) {
            return state.listLog
        },
        getFio(state) {
            return state.fio;
        }

    },


    mutations: {
        onLoad(state, payload) {
            state.load = payload;
        },
        updatelistUsers(state, payload) {
            state.listUsers = payload;
            state.listUsersSelect = [];
            for (let index = 0; index < payload.length; index++) {
                state.listUsersSelect.push(payload[index].lname + ' ' + payload[index].fname + ' ' + payload[index].tname);

            }
        },
        setAuth(state, payload) {
            state.avt = payload;
        },
        setUserAuth(state, payload) {
            state.user = payload;
        },
        updateMenu(state, payload) {
            console.log('ff')
            state.menuList = [];
            if (payload == 2) {
                state.menuList = [
                    {
                        title: 'Пользователи',
                        link: '/person'
                    },
                    {
                        title: 'Логи',
                        link: '/admin/log'
                    },
                    {
                        title: 'Видео',
                        link: '/video/list'
                    },
                ];
            }
            if (payload == 1) {
                state.menuList = [
                    {
                        title: 'Пользователи',
                        link: '/person'
                    },
                    {
                        title: 'Статистика',
                        link: '/statistic'
                    },
                    {
                        title: 'Видео',
                        link: '/video/list'
                    },
                ];
            }
            if (payload == 0) {
                for (let index = 0; index < state.listLesson.length; index++) {
                    state.menuList.push({
                        title: state.listLesson[index].title,
                        link: '/les/' + state.listLesson[index].id,

                    });
                }
            }
            console.log(state.menuList)

        },
        updatePerson(state, payload) {

            state.ListPerson = payload;
            console.log(state.ListPerson);
        },
        updatelistLesson(state, payload) {
            state.listLesson = payload;
        },

        updatelistLog(state, payload) {
            state.listLog = payload;
        }, 
        updatefio(state, payload){
            state.fio = payload
        }
    },


    actions: {
        SetlistPerson({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/account/reg", {
                zn: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatePerson", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        getlistPerson({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/account/list",)
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatePerson", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        dellistPerson({commit}, payload){
            commit("onLoad", true);
            Axios.post("/api/v1/account/del",{
                id: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatePerson", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },

        AuthPerson({ commit }, payload) {
            commit("onLoad", true);
            return new Promise((resolve, reject) => {
                Axios.post("/api/v1/account/login", {
                    zn: payload
                })
                    .then((res) => {
                        console.log(res);
                        if (res.data.message.message != "Успешно") {
                            alert("Неверный логин и пароль!");
                            commit("onLoad", false);
                            resolve(false);
                        } else {
                            commit("onLoad", false);
                            commit("setAuth", true);
                            commit("updatefio", res.data.message.lnm + ' ' + res.data.message.fnm + ' ' + res.data.message.tnm);
                            commit("setUserAuth", new User(res.data.message.jwt));
                            commit("updateMenu", res.data.message.st);
                            Axios.defaults.headers.common["authorization"] = res.data.message.jwt;
                            localStorage.setItem("maksi-user-token", res.data.message.jwt);
                            localStorage.setItem("maksi-user-status", res.data.message.st);
                            localStorage.setItem("maksi-user-fio", res.data.message.lnm + ' ' + res.data.message.fnm + ' ' + res.data.message.tnm);
                            localStorage.setItem("maksi-user-eml", res.data.message.eml);
                            resolve(true);
                        }
                    })
                    .catch((res) => {
                        console.log(res);
                        commit("onLoad", false);
                        reject(false);
                    });
            })
        },

        getExitClient({ commit }) {
            console.log("ex");
            localStorage.removeItem("maksi-user-token");
            localStorage.removeItem("maksi-user-status");
            localStorage.removeItem("maksi-user-fio");
            localStorage.removeItem("maksi-user-eml");
            commit("setUserAuth", null);
            commit("setAuth", false);
        },

        autoLoginUser({ commit }) {
            Axios.defaults.headers.common["authorization"] =
                localStorage.getItem("maksi-user-token");
            commit(
                "setUserAuth",
                new User(localStorage.getItem("maksi-user-token"))
            );
            commit("updatefio", localStorage.getItem("maksi-user-fio"));
                            
            commit("setAuth", true);
            console.log("auto")
            console.log(localStorage.getItem("maksi-user-status"))
            console.log(localStorage.getItem("maksi-user-token"))
            commit("updateMenu", localStorage.getItem("maksi-user-status"));
        },

        getUserList({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/account/list/person")
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistUsers", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        createLoginPass({ commit }, payload) {
            commit("onLoad", true);
            return new Promise((resolve, reject) => {
                Axios.post("/api/v1/account/person/genLogin", {
                    id: payload
                })
                    .then((res) => {
                        console.log(res);
                        commit("onLoad", false);
                        console.log(res.data.message);
                        resolve(res.data.message);
                    })
                    .catch(() => {
                        console.log("error");
                        reject([]);
                    });
            })
        },

        blockUser({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/account/person/block", {
                id: payload
            })
                .then((res) => {
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistUsers", res.data.message);

                })
                .catch(() => {
                    console.log("error");
                });
        },
        getListPersonSpecActionInf({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/main/specialist/get")
                .then((res) => {
                    console.log('special');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updateMainSpecialist", res.data.message);
                })
                .catch((rs) => {
                    console.log('special');
                    console.log(rs);
                });
        },
        saveUserVideo({ commit }, payload) {
            commit("onLoad", true);
            let formData = new FormData();
            formData.append('file', payload);
            return new Promise((resolve, reject) => {
                Axios.post("/api/v1/upload/img", formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        console.log('данные');
                        console.log(res.data);
                        commit("onLoad", false);
                        resolve(res.data.message)
                    })
                    .catch(() => {
                        console.log("error");
                        reject([]);
                    });
            });
        },

        saveUserLesson({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/lesson/set", {
                zn: payload,
            })
                .then((res) => {

                    commit("onLoad", false);
                    commit("updatelistLesson", res.data.message);
                })
                .catch((rs) => {
                    console.log('special');
                    console.log(rs);
                });
        },

        getlistPage({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/lesson/get")
                .then((res) => {
                    console.log('special');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistLesson", res.data.message);
                })
                .catch((rs) => {
                    console.log('special');
                    console.log(rs);
                });
        },

        delUserVideo({ commit }, payload) {
            commit("onLoad", true);
            Axios.post("/api/v1/lesson/del", {
                id: payload
            })
                .then((res) => {
                    console.log('special');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistLesson", res.data.message);
                })
                .catch((rs) => {
                    console.log('special');
                    console.log(rs);
                });
        },


        getlistLog({ commit }) {
            commit("onLoad", true);
            Axios.post("/api/v1/log/get")
                .then((res) => {
                    console.log('special');
                    console.log(res);
                    commit("onLoad", false);
                    commit("updatelistLog", res.data.message);
                })
                .catch((rs) => {
                    console.log('special');
                    console.log(rs);
                });
        }
    },
})