import Vue from 'vue'
import Axios from 'axios';
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import vuePlayer  from  '@algoz098/vue-player'


Vue.config.productionTip = false
Vue.component(vuePlayer)

new Vue({
  store,
  router,
  vuetify,
  created () {
    const token = localStorage.getItem('maksi-user-token')
    //Axios.defaults.baseURL = 'http://maksi.local/';
    Axios.defaults.baseURL = 'https://nis.lang.su/';
    //Axios.defaults.baseURL = 'https://server.b-hire.ru/';
     if (token) {
       this.$store.dispatch('autoLoginUser', token)
     } 
  },
  render: h => h(App)
}).$mount('#app')
